import React from 'react'
import "./styles.css";

const Home = () => {
  return (
    <>
     <div className='home'>
         <div>
         {/* <span>PROJECT E-12</span>
         <p>SIGN-UP OR LOGIN TO CONTINUE</p> */}
         </div>
     </div>
    </>
  )
}

export default Home